import React, { useEffect, useState } from 'react';

import { Icon } from 'react-icons-kit';
import { heart } from 'react-icons-kit/fa/heart';
import { connect } from 'react-redux';

function Listing({ object, onFavoriteClick, favoriteIDs }) {
	const [isFavorite, setIsFavorite] = useState(false);

	var hanldeFavoriteClick = function () {
		setIsFavorite(!isFavorite);
		onFavoriteClick(object);
	};

	useEffect(() => {
		if (favoriteIDs.length) {
			if (favoriteIDs.indexOf(object.id) !== -1) {
				setIsFavorite(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [favoriteIDs]);

	return (
		<div className="listing">
			<a target="_blank" href={`https://templatesapp.com/template/${object.id}`} className="listing-img">
				<img src={object.image} alt="" />
			</a>
			<a onClick={hanldeFavoriteClick} className={isFavorite ? 'favorite in' : 'favorite'}>
				<Icon icon={heart} />
			</a>
			<h4>
				<a target="_blank" href={`https://templatesapp.com/template/${object.id}`}>
					{object.title}
				</a>
			</h4>
			<p className="domain">{object.provider}</p>
			<p>{object.categories.join(', ')}</p>
		</div>
	);
}

var mapStateToProps = (state) => {
	return {
		favoriteIDs: state.app.favoriteIDs,
	};
};

export default connect(mapStateToProps, {})(Listing);
