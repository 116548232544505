import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import './style.scss';

import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Category from './Category.js';
import Favorite from './Favorite.js';

import { getCategories } from '../../actions/AppActions';

function Categories({ categories, getCategories, ...props }) {
	const [mainCategories, setMainCategories] = useState([]);

	useEffect(() => {
		if (categories.length === 0) {
			getCategories();
		} else {
			var mainCats = categories;
			setMainCategories(mainCats);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [categories]);

	return (
		<>
			{mainCategories.length ? (
				<div className="owl-theme-category">
					<OwlCarousel
						options={{
							nav: true,
							dots: false,
							rewind: false,
							slideBy: 4,
							navText: ['<span>‹</span>', '<span>›</span>'],
							responsive: {
								0: { items: 4 },
								768: { items: 6 },
								1200: { items: 10 },
							},
						}}
					>
						{props.favorites && <Favorite />}
						{mainCategories.map((cat, index) => (
							<Category cat={cat} key={index} />
						))}
					</OwlCarousel>
				</div>
			) : (
				<div className="loading"></div>
			)}
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		categories: state.app.categories,
	};
};

export default connect(mapStateToProps, {
	getCategories,
})(Categories);
