import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import Listings from '../../components/Listings';
import { searchCategory, selectCategory } from '../../actions/AppActions';

import './style.scss';

function ListingsPage({ selected_category, categories, selectCategory, searchCategory }) {
	let { id } = useParams();

	useEffect(() => {
		if (!id) {
			return;
		}
		switch (id) {
			case 'favorites':
				selectCategory(id);
				break;
			case 'top':
				selectCategory(id);
				searchCategory(id);
				break;
			default:
				var cat = categories.find((el) => el.ID == id);
				selectCategory(cat);
				searchCategory(cat);
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	return (
		<div className="toolbar">
			{selected_category && <Listings />}
			{/* <Listings /> */}
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		selected_category: state.app.selected_category,
		categories: state.app.categories,
	};
};

export default connect(mapStateToProps, {
	selectCategory,
	searchCategory,
})(ListingsPage);
