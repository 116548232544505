import {
	GET_CATEGORIES,
	SELECT_CATEGORY,
	GET_PROVIDERS,
	SELECT_PROVIDER,
	SEARCHING_RESULTS,
	SEARCHING_MORE_RESULTS,
	APP_SEARCH_RESULTS,
	APP_SEARCH_MORE_RESULTS,
	ADD_FAVORITE,
	SET_APP_TOKEN,
} from '../actions/types';

const INITIAL_STATE = {
	categories: [
		{
			Category: 'Resumes',
			ID: 142,
			PrimaryImage: require('../images/icons/timeline.png'),
		},
		{
			Category: 'Brochures',
			ID: 95,
			PrimaryImage: require('../images/icons/brochure.png'),
		},
		{
			Category: 'Presentations',
			ID: 31,
			PrimaryImage: require('../images/icons/molecular.png'),
		},
		{
			Category: 'Budgets',
			ID: 98,
			PrimaryImage: require('../images/icons/piggy-bank.png'),
		},
		{
			Category: 'Invoices',
			ID: 73,
			PrimaryImage: require('../images/icons/to-do-list.png'),
		},
		{
			Category: 'Letters',
			ID: 46,
			PrimaryImage: require('../images/icons/letter.png'),
		},
		{
			Category: 'Flyers',
			ID: 76,
			PrimaryImage: require('../images/icons/id-card.png'),
		},
		{
			Category: 'Business',
			ID: 55,
			PrimaryImage: require('../images/icons/star.png'),
		},
		{
			Category: 'Invitations',
			ID: 83,
			PrimaryImage: require('../images/icons/brochure.png'),
		},
		{
			Category: 'Posters',
			ID: 89,
			PrimaryImage: require('../images/icons/timeline.png'),
		},
		{
			Category: 'Cards',
			ID: 40,
			PrimaryImage: require('../images/icons/id-card.png'),
		},
		{
			Category: 'Logos',
			ID: 195,
			PrimaryImage: require('../images/icons/apple.png'),
		},
	],
	selected_category: null,
	providers: [],
	selected_provider: null,
	searching: false,
	results: [],
	hasMore: false,
	page: 1,
	loadingMore: false,
	favorites: [],
	favoriteIDs: [],
	token: '',
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_CATEGORIES:
			return {
				...state,
				categories: action.payload,
			};
		case SELECT_CATEGORY:
			return {
				...state,
				selected_category: action.payload,
			};
		case GET_PROVIDERS:
			return  {
				...state,
				providers: action.payload,
			};
		case SELECT_PROVIDER: 
			return {
				...state,
				selected_provider: action.payload
			};
		case SEARCHING_RESULTS:
			return {
				...state,
				searching: true,
			};
		case SEARCHING_MORE_RESULTS:
			return {
				...state,
				loadingMore: true,
			};
		case APP_SEARCH_RESULTS:
			if (action.payload == undefined) {
				action.payload = [];
			}
			return {
				...state,
				searching: false,
				results: action.payload,
				hasMore: action.payload.length > 0,
				page: 1,
			};
		case APP_SEARCH_MORE_RESULTS:
			return {
				...state,
				loadingMore: false,
				results: [...state.results, ...action.payload],
				hasMore: action.payload.length > 0,
				page: state.page + 1,
			};
		case ADD_FAVORITE:
			return {
				...state,
				favorites: action.payload,
				favoriteIDs: action.payload.map((el) => el.id),
			};
		case SET_APP_TOKEN:
			return {
				...state,
				token: action.payload,
			};
		default:
			return state;
	}
};
