import React, {useState, useEffect} from 'react';

function ProviderSelect({selected, providers, onChange}) {
    var hanldeChange = function(e) {
        if(!e.target.value) {
            onChange(null);
        } else {
            onChange(providers[e.target.value].id);
        }
    }
    return providers.length > 0 ? (
        <select onChange={hanldeChange} defaultValue={selected ? selected.id : ""} className="custom-select">
            {/* <option>{selected.Category}</option> */}
            <option value="">All Providers</option>
            {providers.map((prov, i) => (
            <option key={i} data-pid={prov.id} value={i}>{prov.name}</option>
            ))}
        </select>
    ) : '';
}

export default ProviderSelect;