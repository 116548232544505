import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setPreferencesInit } from '../../actions/PreferencesActions';

function Category({ setPreferencesInit, cat, onClick, selected_category }) {
	const [selected, setSelected] = useState(false);

	// var hanldeClick = function () {
	// 	setSelected(true);
	// 	onClick(cat);
	// };

	useEffect(() => {
		if (selected_category) {
			if (selected_category.ID !== cat.ID) {
				setSelected(false);
			} else if (!selected) {
				setSelected(true);
			}
		} else {
			setSelected(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected_category]);

	return (
		<Link
			onClick={() => {
				setPreferencesInit(true);
			}}
			to={`/listings/${cat.ID}`}
			className={selected ? 'category-item icon selected' : 'category-item icon'}
		>
			<img alt={cat.Category} src={cat.PrimaryImage} />
			<span>{cat.Category}</span>
		</Link>
	);
}

const mapStateToProps = (state) => {
	return {
		selected_category: state.app.selected_category,
	};
};

export default connect(mapStateToProps, {
	setPreferencesInit,
})(Category);
