import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {Link, useHistory} from 'react-router-dom';

import { searchResults, addFavorite, loadMoreResults, selectProvider, getProviders } from '../../actions/AppActions';

import Listing from './listing.js';
import SearchForm from '../SearchForm';
import AmazonAds from './AmazonAds.js';
import ProviderSelect from './ProviderSelect.js';

import loadingGif from '../../images/loading.gif';

import './style.scss';

function Search({
    selected_category, 
    selected_provider,
    providers,
    searching, 
    results, 
    searchResults, 
    selectProvider,
    loadMoreResults,
    addFavorite,
    getProviders,
    page,
    loadingMore,
    hasMore,
    query
}) {

    const history = useHistory();
    const [searchedTerm, setSearchedTerm] = useState(query);

    // set isSearch if q is defined
    useEffect(() => {
        if(typeof query !== 'undefined') {
            selectProvider(null);
            searchResults(query);
            setSearchedTerm(query);
        }
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    // inifinite scroll
    useEffect(() => {
        window.addEventListener('scroll', hanldeScroll);

        return function cleanup() {
            window.removeEventListener('scroll', hanldeScroll);
        };
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [results, searching, loadingMore]);

    useEffect(() => {
        if(providers.length == 0) {
            getProviders();
        }
    }, [providers]);

    // Initinite Scroll Handler
    var hanldeScroll = function(e) {
        if(window.document.body.scrollHeight - window.innerHeight <= window.scrollY) {
            if(results.length > 0 && !searching && !loadingMore && hasMore) {
                loadMoreResults(searchedTerm, page+1);
            }
        }
    };

    // Load Search Results
    var handleSearchSubmit = function(query) {
        history.push('/search?q=' + query);
    };

    // Add object to favorites
    var handleFavoriteClick = function(object) {
        addFavorite(object);
    };

    // Load Results for Subcategory
    var handleProviderChange = function(prov) { 
        selectProvider(prov);
        searchResults(query);
    };
    
    return (
        <div className="container listings">
            <div className="row py-4">
                <div className="col d-flex align-items-center">
                    <Link className="exit" to="/">&times;</Link>
                    <ProviderSelect onChange={handleProviderChange} selected={selected_provider} providers={providers} />
                </div>
                <div className="col d-flex justify-content-end">
                    <SearchForm defaultValue={searchedTerm} reset={selected_category} onSubmit={handleSearchSubmit} placeholder="Search Templates" />
                </div>
            </div>
            {searching ? (
                <div className="loading">
                    <img src={loadingGif} width={200} />
                </div>
            ) : (
                results.length ? (
                    <>
                        <div className="row pb-4">
                            <AmazonAds />
                        </div>
                        <h4 className="mb-4">Search Results For: "{searchedTerm}"</h4>
                        <div className="row">
                            {results.map((result, idx) => (
                                <div key={result.id} className="col-lg-3 col-md-4 col-sm-6">
                                    <Listing key={result.id} onFavoriteClick={handleFavoriteClick} object={result} />
                                </div>
                            ))}
                        </div>
                    </>
                ) : (
                    <div className="not-found">
                        No Results Found<br/> 
                        Please Search Again.
                    </div>
                )
            )}
            {loadingMore && (
                <div className="loading">
                    <img src={loadingGif} width={200} />
                </div>
            )}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        searching: state.app.searching,
        results: state.app.results,
        favorites: state.app.favorites,
        hasMore: state.app.hasMore,
        loadingMore: state.app.loadingMore,
        page: state.app.page,
        selected_provider: state.app.selected_provider,
        providers: state.app.providers
    }
};

export default connect(mapStateToProps, {
    searchResults,
    addFavorite,
    loadMoreResults,
    selectProvider,
    getProviders
})(Search);