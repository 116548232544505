import React from 'react';

import logo from '../../images/logo.png';
import './style.scss';

function Header() {
	return (
		<header>
			<div className="container">
				<img alt="TemplatesApp" className={'logo'} src={logo} />
			</div>
		</header>
	);
}

export default Header;
